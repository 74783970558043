// PaymentTable component
import PropTypes from "prop-types";
import {
    Box,
    Card,
    Divider,
    Grid,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import ProgressBars from "../../utils/loading";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {DateTimeFormateTimeStamp} from "../../utils/dateTime-fomater";
import {useNavigate} from "react-router-dom";
import {SeverityPill} from "../../components/severity-pill";
import {formatDisplayNumber} from "../../utils/number-formter";
import React, {useState} from "react";
import ImageViewer from "../../components/image-popup";
import AddDelivery from "./add-deliveries";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeletePopup from "../../components/deletePopup";
import {DELETE_DELIVERY_ENDPOINT} from "../../utils/constants";

export const DeliveriesTable = (props) => {
    const {
        totals = {},
        items = [],
        onPageChange = () => {
        },
        onRowsPerPageChange,
        page = 0,
        rowsPerPage = 0,
        selected = [],
        isLoading = false,
        componentRef,
    } = props;
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openEdit, setOpenEdit] = useState({
        open: false,
        data: null,
        type: "",
    });
    const [dialogDeleteOpen, setDialogDeleteOpen] = React.useState(false);
    const [selectedEntity, setSelectedEntity] = React.useState({
        id: null,
        name: "",
    });

    const handleNavigate = (purchaseOrder) => {
        const {id} = purchaseOrder;
        navigate(`/purchase-order/${id}`);
    };

    const handleEditClick = (event, delivery) => {
        event.stopPropagation();
        const type =
            delivery.delivery.purchase_order.product.is_tangible &&
            delivery.delivery.purchase_order.product.unit_of_measurement
                ? "lpo"
                : "lso";
        setOpenEdit({open: true, data: delivery, type: type});
        handleMenuClose();
    };

    const handleDeleteClick = (delivery) => {
        setDialogDeleteOpen(true);
        setSelectedEntity({
            id: delivery.delivery.id,
            type: `this delivery (LPO Number.${delivery.delivery.purchase_order.order_number})`,
        });
        setAnchorEl(null);
        handleMenuClose();
    };

    const handleMenuOpen = (event, delivery) => {
        event.stopPropagation();
        setOpenEdit({open: false, data: delivery, type: ""});
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Card>
            <Box ref={componentRef} sx={{minWidth: 800}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>LPO Number</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Invoiced Amount</TableCell>
                                <TableCell>Total Delivered</TableCell>
                                <TableCell>In Stock</TableCell>
                                <TableCell>Delivered By</TableCell>
                                <TableCell>Received By</TableCell>
                                <TableCell>Supplier</TableCell>
                                <TableCell>Date Delivered</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>

                        {isLoading ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                minHeight: 200,
                                            }}
                                        >
                                            <ProgressBars/>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : items.length === 0 ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                minHeight: 200,
                                            }}
                                        >
                                            <CustomNoRowsOverlay/>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {items.map((delivery, index) => {
                                    const rowNum = page * rowsPerPage + index + 1;
                                    const isSelected = selected.includes(delivery.delivery.id);

                                    return (
                                        <TableRow hover key={delivery.id} selected={isSelected}>
                                            <TableCell
                                                onClick={() =>
                                                    handleNavigate(delivery.delivery.purchase_order)
                                                }
                                            >
                                                {rowNum}
                                            </TableCell>
                                            <TableCell
                                                onClick={() =>
                                                    handleNavigate(delivery.delivery.purchase_order)
                                                }
                                            >
                                                <ListItemText
                                                    primary={
                                                        delivery.delivery.purchase_order.order_number
                                                    }
                                                    primaryTypographyProps={{variant: "subtitle1"}}
                                                    secondary={
                                                        delivery.delivery.delivery_image && (
                                                            <ImageViewer
                                                                imageUrl={delivery.delivery.delivery_image}
                                                                title="Delivery image"
                                                                open={open}
                                                                setOpen={setOpen}
                                                            />
                                                        )
                                                    }
                                                    secondaryTypographyProps={{variant: "caption"}}
                                                />
                                            </TableCell>
                                            <TableCell
                                                onClick={() =>
                                                    handleNavigate(delivery.delivery.purchase_order)
                                                }
                                            >
                                                {delivery.delivery.purchase_order.product.item_name}
                                            </TableCell>
                                            <TableCell>
                                                <SeverityPill color="success">
                                                    {`${delivery.delivery.quantity} ${delivery.delivery.purchase_order.product.unit_of_measurement}`}
                                                </SeverityPill>
                                            </TableCell>
                                            <TableCell>
                                                <SeverityPill color="warning">
                                                    {`KES. ${formatDisplayNumber(
                                                        delivery.delivery.invoice_amount
                                                    )}`}
                                                </SeverityPill>
                                            </TableCell>
                                            <TableCell>
                                                {`${parseFloat(delivery.delivery.current_total_delivered) + parseFloat(delivery.delivery.quantity)} ${
                                                    delivery.delivery.purchase_order.product
                                                        .unit_of_measurement
                                                }`}
                                            </TableCell>
                                            <TableCell>
                                                {`${formatDisplayNumber(
                                                    parseFloat(delivery.delivery.current_stock_qty) +
                                                    parseFloat(delivery.delivery.quantity)
                                                )}`}
                                            </TableCell>
                                            <TableCell>{delivery.delivery.delivered_by}</TableCell>
                                            <TableCell>
                                                {delivery.delivery.processed_by.full_name}
                                            </TableCell>
                                            <TableCell>
                                                <ListItemText
                                                    primary={`${delivery.delivery.purchase_order.supplier.full_name} - ${delivery.delivery.purchase_order.supplier.phone_number}`}
                                                    secondary={
                                                        delivery.delivery.purchase_order.supplier
                                                            .company_name
                                                    }
                                                    secondaryTypographyProps={{variant: "caption"}}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {DateTimeFormateTimeStamp(delivery.delivery.timestamp)}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    aria-controls={`menu-${delivery.delivery.id}`}
                                                    aria-haspopup="true"
                                                    onClick={(event) => handleMenuOpen(event, delivery)}
                                                >
                                                    <MoreVertIcon/>
                                                </IconButton>
                                                <Menu
                                                    id={`menu-${delivery.delivery.id}`}
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleMenuClose}
                                                >
                                                    <MenuItem
                                                        onClick={(event) =>
                                                            handleEditClick(event, delivery)
                                                        }
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                    <Divider/>

                                                    <MenuItem onClick={() => handleDeleteClick(delivery)}>
                                                        Delete
                                                    </MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Box>

            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{marginLeft: 16}}
            >
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" gutterBottom>
                        Total Invoiced Amount:{" "}
                        <SeverityPill color="warning">
                            {`KES. ${formatDisplayNumber(totals.total_invoice_amount)}`}
                        </SeverityPill>
                    </Typography>
                </Grid>

                <TablePagination
                    component="div"
                    count={totals.total}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[25, 50, 100]}
                />
            </Grid>

            {openEdit.open && (
                <div style={{display: "none"}}>
                    <AddDelivery mode="edit" type={openEdit.type} data={openEdit.data}/>
                </div>
            )}
            {dialogDeleteOpen && (
                <DeletePopup
                    dialogOpen={dialogDeleteOpen}
                    setDialogOpen={setDialogDeleteOpen}
                    selectedEntity={selectedEntity}
                    urlLink={DELETE_DELIVERY_ENDPOINT}
                />
            )}
        </Card>
    );
};

DeliveriesTable.propTypes = {
    totals: PropTypes.object,
    items: PropTypes.array,
    onDeselectAll: PropTypes.func,
    onDeselectOne: PropTypes.func,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectOne: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    selected: PropTypes.array,
    isLoading: PropTypes.bool,
};
