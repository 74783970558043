import {Box, Unstable_Grid2 as Grid} from "@mui/material";
import {ActiveDeliveryOrders} from "../sections/overview/overview-total_orders_delivered";
import {OverviewTodayDeliveries} from "../sections/overview/overview-deliveries-today";
import {OverviewThisWeekDeliveries} from "../sections/overview/overview-deliveries-this-week";
import {OverviewThisMonthDeliveries} from "../sections/overview/overview-deliveries-past-30-days";
import {OverviewThisYearDeliveries} from "../sections/overview/overview-deliveries-past-365-days";
import {OverviewRecentDeliveries} from "../sections/overview/overview-recent-deliveries";
import {OverviewSchoolProducts} from "../sections/overview/overview-school-products";
import {OverviewPayments} from "../sections/overview/overview-payment";
import {OverviewTotalOrdersAmount} from "../sections/overview/overview-total-orders-amount";
import {OverviewTotalOrdersPaid} from "../sections/overview/overview-total-orders-paid";
import {OverviewTraffic} from "../sections/overview/overview-traffic";
import {useEffect, useState} from "react";
import {OverviewSuppliers} from "../sections/overview/overview-suppliers";
import ProgressBars from "../utils/loading";
import {DASHBOARD_ENDPOINT} from "../utils/constants";
import {makeNetworkCall} from "../utils/fetchData";

const OverViwPage = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);

    useEffect(() => {
        const fetchData = async () => makeNetworkCall(
            DASHBOARD_ENDPOINT,
            {},
            setData,
            setPage,
            setIsLoading,
            {},
            "GET"
        );
        fetchData();
    }, []);
    const formatNumber = (number) => {
        const formatter = new Intl.NumberFormat("en-US");
        return formatter.format(number);
    };

    return (
        <>
            {isLoading ? (
                <ProgressBars/>
            ) : (
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid xs={12} sm={6} lg={3}>
                            <OverviewTotalOrdersAmount
                                difference={16}
                                positive={false}
                                sx={{height: "100%"}}
                                value={`ksh. ${formatNumber(data?.total_orders_delivered - data?.total_orders_paid)}`}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} lg={3}>
                            <ActiveDeliveryOrders
                                difference={12}
                                positive
                                sx={{height: "100%"}}
                                value={(data?.not_delivered_orders + data?.partially_delivered_orders)}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} lg={3}>
                            <OverviewTotalOrdersPaid
                                sx={{height: "100%"}}
                                value={(data?.all_orders - data?.fully_paid_orders + data?.partially_paid_orders)}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} lg={3}>
                            <OverviewSuppliers
                                sx={{height: "100%"}}
                                value={data?.all_suppliers}
                            />
                        </Grid>

                        <Grid xs={12} sm={6} lg={3}>
                            <OverviewTodayDeliveries
                                difference={16}
                                positive={false}
                                sx={{height: "100%"}}
                                value={`ksh. ${formatNumber(data?.today_deliveries)}`}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} lg={3}>
                            <OverviewThisWeekDeliveries
                                difference={12}
                                positive
                                sx={{height: "100%"}}
                                value={formatNumber(data?.past_7_deliveries)}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} lg={3}>
                            <OverviewThisMonthDeliveries
                                sx={{height: "100%"}}
                                value={`ksh. ${formatNumber(data?.past_30_deliveries)}`}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} lg={3}>
                            <OverviewThisYearDeliveries
                                sx={{height: "100%"}}
                                value={formatNumber(data?.past_365_deliveries)}
                            />
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <OverviewPayments
                                chartSeries={[
                                    {
                                        data: data?.past_12_months
                                            ?.slice()
                                            .reverse()
                                            .map((item) => ({
                                                //x: item.month + " " + item.this_year,
                                                x: item.month,
                                                y: item.current_month_payments,
                                            })),
                                        label: "DELIVERIES"
                                    },
                                    {
                                        data: data?.past_12_months
                                            ?.slice()
                                            .reverse()
                                            .map((item) => ({
                                                //x: item.month + " " + item.last_year,
                                                x: item.month,
                                                y: item.payment,
                                            })),
                                        label: "PAYMENTS"
                                    },
                                ]}
                                sx={{height: "100%"}}
                            />
                        </Grid>

                        <Grid xs={12} md={6} lg={4}>
                            <OverviewTraffic
                                chartSeries={[
                                    data.total_orders_delivered,
                                    data.undelivered_orders
                                ]}
                                labels={["Delivered", "Not Delivered"]}
                                sx={{height: "100%"}}
                                total={parseFloat(data.total_orders_delivered) + parseFloat(data.undelivered_orders)}
                            />
                        </Grid>
                        <Grid xs={12} md={6} lg={6}>
                            <OverviewSchoolProducts
                                page={page}
                                products={data.school_products}
                                sx={{height: "100%"}}
                            />
                        </Grid>
                        <Grid xs={12} md={12} lg={6}>
                            <OverviewRecentDeliveries
                                rowsPerPage={10}
                                orders={data.recent_deliveries}
                                sx={{height: "100%"}}
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default OverViwPage;
