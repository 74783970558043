// Page component
import React, {useCallback, useEffect, useMemo, useRef, useState,} from "react";
import {Box, Button, Stack, SvgIcon, Tab, Tabs, Typography,} from "@mui/material";
import {useSelection} from "../../hooks/use-selection";
import {StudentAllocationsSearch} from "../../sections/studentAllocations/student_allocations-search";
import {StudentListTable} from "../../sections/studentAllocations/student-list-table ";
import {AllocationItemsSearch} from "../../sections/studentAllocations/items/items-search";
import {IssuedItemTable} from "../../sections/studentAllocations/items/issued-items-table";
import {ReceivedItemTable} from "../../sections/studentAllocations/items/recieved-items-table";
import {Helmet} from "react-helmet";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {printReport} from "../../utils/print-documents";
import {ExportTableButton} from "../../utils/export-document";
import * as actions from "../../layouts/dashboard/actions";
import {IssuedItemTabTable} from "../../sections/studentAllocations/issuedItems-tab/issuedItems-table";
import {ReceivedItemTabTable} from "../../sections/studentAllocations/receivedItems-tab/receivedItems-table";
import {
    DEFAULT_PER_PAGE,
    SCHOOL_STUDENT_ITEMS_ENDPOINT,
    SEARCH_STUDENT_ENDPOINT,
    STUDENT_ITEMS_ISSUANCE_ENDPOINT,
    STUDENT_ITEMS_RETURNS_ENDPOINT,
    STUDENTS_PER_STREAM_ENDPOINT
} from "../../utils/constants";
import {makeNetworkCall} from "../../utils/fetchData";

const useAllocations = (
    page,
    rowsPerPage,
    inputData,
    student,
    setStudent,
    value,
    date
) => {
    const [data, setData] = useState([]);
    const [receivedItemsData, setReceivedItemsData] = useState([]);
    const [receivedTabItemsData, setReceivedTabItemsData] = useState([]);
    const [issuedTabItemsData, setIssuedTabItemsData] = useState([]);
    const [studentListData, setStudentListData] = useState([]);
    const [receivedTotals, setReceivedTotals] = useState({});
    const [issuedTotals, setIssuedTotals] = useState({});
    const [streamItemsTotals, setStreamItemsTotals] = useState({});
    const [streamStudentsTotals, setStreamStudentsTotals] = useState({});
    const [isLoadingStudentList, setIsLoadingStudentList] = useState(false);
    const [isLoadingIssuanceItems, setIsLoadingIssuanceItems] = useState(false);
    const [searchStudent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingIssuedData, setLoadingIssuedData] = useState(false);
    const [loadingReceivedData, setLoadingReceivedData] = useState(false);

    const postData = {
        form: inputData.forms,
        stream: inputData.selectedStream,
    };

    const formData = new FormData();

    for (let key in postData) {
        formData.append(key, postData[key]);
    }

    const fetchStudentListData = async () => makeNetworkCall(
        STUDENTS_PER_STREAM_ENDPOINT,
        {
            form: inputData.forms,
            stream: inputData.selectedStream,
        },
        setStudentListData,
        setStreamStudentsTotals,
        setIsLoadingStudentList,
        {},
        "POST"
    );

    let apiUrl;
    if (inputData.itemType === "received") {
        apiUrl =
            STUDENT_ITEMS_RETURNS_ENDPOINT;
    } else {
        apiUrl =
            STUDENT_ITEMS_ISSUANCE_ENDPOINT;
    }
    let productId;
    if (inputData.productID === '') {
        productId = 0;
    } else {
        productId = inputData.productID;
    }

    const fetchIssuanceItemsData = async () => makeNetworkCall(
            apiUrl,
            {
                form: inputData.forms,
                stream: inputData.selectedStream,
                product_id: productId,
            },
            setReceivedItemsData,
            setStreamItemsTotals,
            setIsLoadingIssuanceItems,
            {},
            "POST"
        )
    ;


    useEffect(() => {
        if (!receivedItemsData || receivedItemsData.length === 0) {
            fetchIssuanceItemsData();
        }
    }, [inputData.stream, inputData.forms, inputData.itemType, inputData]);

    useEffect(() => {
        fetchIssuanceItemsData();
    }, [inputData.forms, inputData, inputData.stream, value]);

    const getStudent = async () => makeNetworkCall(
        SEARCH_STUDENT_ENDPOINT,
        {
            student_number: student,
        },
        setStudentListData,
        null,
        setLoading,
        {},
        "POST"
    );

    useEffect(() => {
        if (student) {
            getStudent();
        }
    }, [searchStudent]);

    useEffect(() => {
        if (!studentListData || studentListData.length === 0) {
            fetchStudentListData();
        }
    }, [inputData.forms, inputData.stream, inputData, value, date]);

    const fetchReceivedTabItemsData = async () => makeNetworkCall(
        SCHOOL_STUDENT_ITEMS_ENDPOINT,
        {
            offset: page * rowsPerPage,
            per_page: rowsPerPage,
            status: "received",
        },
        setReceivedTabItemsData,
        setReceivedTotals,
        setLoadingReceivedData,
        {},
        "POST"
    );

    const fetchIssuedTabItemsData = async () => makeNetworkCall(
        SCHOOL_STUDENT_ITEMS_ENDPOINT,
        {
            offset: page * rowsPerPage,
            per_page: rowsPerPage,
            status: "issued",
        },
        setIssuedTabItemsData,
        setIssuedTotals,
        setLoadingIssuedData,
        {},
        "POST"
    );

    useEffect(() => {
        value === 2 && fetchReceivedTabItemsData();
    }, [value === 2]);

    useEffect(() => {
        value === 1 && fetchIssuedTabItemsData();
    }, [value === 1]);

    return {
        data,
        receivedTotals,
        issuedTotals,
        streamItemsTotals,
        streamStudentsTotals,
        isLoadingStudentList,
        isLoadingIssuanceItems,
        studentListData,
        setData,
        getStudent,
        student,
        setStudent,
        receivedItemsData,
        setIsLoadingIssuanceItems,
        setIsLoadingStudentList,
        receivedTabItemsData,
        issuedTabItemsData,
        loading,
        loadingIssuedData,
        loadingReceivedData,
    };
};

const useAllocationIds = (allocations) => {
    return useMemo(() => {
        return allocations.map((allocation) => allocation.id);
    }, [allocations]);
};

const StudentAllocationsPage = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);
    const [value, setValue] = useState(0);
    const [inputData, setInputData] = useState({
        forms: 1,
        selectedStream: "",
        itemType: "received",
        productID: "",
        stream: "",
    });
    const [student, setStudent] = useState("");
    const [date, setDate] = useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const {
        data: allocations,
        studentListData,
        receivedTotals,
        issuedTotals,
        streamItemsTotals,
        streamStudentsTotals,
        setData,
        getStudent,
        receivedItemsData,
        isLoadingStudentList,
        isLoadingIssuanceItems,
        setIsLoadingIssuanceItems,
        setIsLoadingStudentList,
        receivedTabItemsData,
        issuedTabItemsData,
        loadingIssuedData,
        loadingReceivedData,
    } = useAllocations(
        page,
        rowsPerPage,
        inputData,
        student,
        setStudent,
        value,
        date
    );
    const allocationIds = useAllocationIds(allocations);
    const allocationsSelection = useSelection(allocationIds);
    const componentRef = useRef();

    const handlePageChange = useCallback((event, value) => {
        setPage(value);
    }, []);

    const handleRowsPerPageChange = useCallback((event) => {
        setRowsPerPage(event.target.value);
    }, []);

    return (
        <>
            <Helmet>
                <title> Procurement | Students</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                }}
            >
                <Stack spacing={3}>
                    <Stack direction="row" justifyContent="space-between" spacing={4}>
                        <Stack spacing={1}>
                            <Typography variant="h4">Student Items </Typography>

                            <Stack alignItems="center" direction="row" spacing={1}>
                                {actions.canPrintStudent && (
                                    <Button
                                        onClick={() =>
                                            printReport(componentRef, "List of Student Data")
                                        }
                                        color="inherit"
                                        startIcon={
                                            <SvgIcon fontSize="small">
                                                <LocalPrintshopIcon/>
                                            </SvgIcon>
                                        }
                                    >
                                        Print
                                    </Button>
                                )}

                                {actions.canExportStudent && (
                                    <ExportTableButton
                                        componentRef={componentRef}
                                        pageTittle="Student"
                                    />
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        centered
                    >
                        <Tab label="Students"/>
                        <Tab label="ISSUED ITEMS"/>
                        <Tab label="RECEIVED ITEMS"/>
                        <Tab label="ITEMS PER STREAM"/>
                    </Tabs>
                    {value === 0 && (
                        <>
                            <StudentAllocationsSearch
                                data={allocations}
                                setData={setData}
                                inputData={inputData}
                                getStudent={getStudent}
                                setInputData={setInputData}
                                student={student}
                                setStudent={setStudent}
                                setIsLoading={setIsLoadingStudentList}
                                page={page}
                                rowsPerPage={rowsPerPage}
                            />
                            <StudentListTable
                                count={streamStudentsTotals.total}
                                items={studentListData}
                                onDeselectAll={allocationsSelection.handleDeselectAll}
                                onDeselectOne={allocationsSelection.handleDeselectOne}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleRowsPerPageChange}
                                onSelectAll={allocationsSelection.handleSelectAll}
                                onSelectOne={allocationsSelection.handleSelectOne}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                selected={allocationsSelection.selected}
                                isLoading={isLoadingStudentList}
                                componentRef={componentRef}
                            />
                        </>
                    )}

                    {value === 1 && (
                        <IssuedItemTabTable
                            count={issuedTotals.total}
                            items={issuedTabItemsData}
                            onDeselectAll={allocationsSelection.handleDeselectAll}
                            onDeselectOne={allocationsSelection.handleDeselectOne}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            onSelectAll={allocationsSelection.handleSelectAll}
                            onSelectOne={allocationsSelection.handleSelectOne}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            selected={allocationsSelection.selected}
                            isLoading={loadingIssuedData}
                            componentRef={componentRef}
                        />
                    )}
                    {value === 2 && (
                        <ReceivedItemTabTable
                            count={receivedTotals.total}
                            items={receivedTabItemsData}
                            onDeselectAll={allocationsSelection.handleDeselectAll}
                            onDeselectOne={allocationsSelection.handleDeselectOne}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            onSelectAll={allocationsSelection.handleSelectAll}
                            onSelectOne={allocationsSelection.handleSelectOne}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            selected={allocationsSelection.selected}
                            isLoading={loadingReceivedData}
                            componentRef={componentRef}
                        />
                    )}

                    {value === 3 && (
                        <>
                            <AllocationItemsSearch
                                setData={setData}
                                inputData={inputData}
                                getStudent={getStudent}
                                setInputData={setInputData}
                                student={student}
                                setIsLoading={setIsLoadingIssuanceItems}
                                setStudent={setStudent}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                date={date}
                                setDate={setDate}
                            />
                            {inputData.itemType === "received" ? (
                                <ReceivedItemTable
                                    count={streamItemsTotals.total}
                                    items={receivedItemsData}
                                    onDeselectAll={allocationsSelection.handleDeselectAll}
                                    onDeselectOne={allocationsSelection.handleDeselectOne}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                    onSelectAll={allocationsSelection.handleSelectAll}
                                    onSelectOne={allocationsSelection.handleSelectOne}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    selected={allocationsSelection.selected}
                                    isLoading={isLoadingIssuanceItems}
                                    componentRef={componentRef}
                                />
                            ) : (
                                <IssuedItemTable
                                    count={streamItemsTotals.total}
                                    items={receivedItemsData}
                                    onDeselectAll={allocationsSelection.handleDeselectAll}
                                    onDeselectOne={allocationsSelection.handleDeselectOne}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                    onSelectAll={allocationsSelection.handleSelectAll}
                                    onSelectOne={allocationsSelection.handleSelectOne}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    selected={allocationsSelection.selected}
                                    isLoading={isLoadingIssuanceItems}
                                    componentRef={componentRef}
                                />
                            )}
                        </>
                    )}
                </Stack>
            </Box>
        </>
    );
};

export default StudentAllocationsPage;
